<template>
  <div class="report-page">
    <v-row no-gutters>
      <v-col cols="12" md="4"><h2>Create New Report</h2></v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <v-form
          @submit.prevent="submit"
          v-model="valid"
          ref="form"
          class="report-form"
          autocomplete="new-password"
        >
          <v-row no-gutters>
            <v-col cols="12" md="4" class="column-name">
              <div class="report-info report-name">
                <div class="title">1. Report Details</div>
                <div class="content">
                  <div class="label">Report Name</div>
                  <div class="input">
                    <v-text-field
                      v-model="reportName"
                      label=""
                      required
                      autocomplete="new-password"
                      outlined
                      :rules="reportNameRules"
                    ></v-text-field>
                  </div>
                </div>
                <div class="content">
                  <div class="label">Report Template</div>
                  <div class="dropdown" @click.stop>
                    <v-select
                      v-model="reportTemplate"
                      :items="reportTemplates"
                      item-text="name"
                      item-value="name"
                      label="New Template"
                      class="drd-report-template"
                      single-line
                      outlined
                      clearable
                      ref="select"
                    ></v-select>
                  </div>
                </div>
                <div class="content rp-template-name">
                  <div class="label">Report Template Name</div>
                  <div class="input">
                    <v-text-field
                      v-model="reportTemplateName"
                      label=""
                      autocomplete="new-password"
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <div
                  class="error-message color-pink"
                  :class="{ show: this.rpTemplateNameCountValidationMsg }"
                >
                  {{ rpTemplateNameCountValidationMsg }}
                </div>
                <div class="chbox-container">
                  <v-checkbox
                    v-model="saveTemplate"
                    class="chbox"
                    label="Save Template"
                  ></v-checkbox>
                </div>
                <div class="content">
                  <div class="label">Date Range</div>
                  <div class="input dtp">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="dates"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateRangeText"
                          label=""
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          :rules="dateRangeRules"
                          ><template v-slot:append
                            ><v-icon v-on="on">mdi-calendar</v-icon></template
                          ></v-text-field
                        >
                      </template>
                      <v-date-picker v-model="dates" no-title scrollable range>
                        <v-spacer></v-spacer>
                        <v-btn
                          :ripple="false"
                          class="btnDtp cancel"
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          :ripple="false"
                          class="btnDtp ok"
                          text
                          color="primary"
                          @click="
                            $refs.menu.save(dates);
                            menu = false;
                          "
                        >
                          OK
                        </v-btn></v-date-picker
                      >
                    </v-menu>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="column-type">
              <div class="report-info report-type">
                <div class="title">2. Report Type</div>
                <div class="radio-group">
                  <v-radio-group v-model="reportType" :rules="reportTypeRules">
                    <v-radio
                      v-for="(type, index) in reportTypes"
                      :key="index"
                      :label="type.name"
                      :value="type.value"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="column-country">
              <div class="report-info country">
                <div class="title">3. Country &amp; Region</div>
                <div class="expansion-panels">
                  <v-expansion-panels v-model="regionPanel" multiple>
                    <v-expansion-panel v-for="(item, i) in mockRegions" :key="i">
                      <v-expansion-panel-header disable-icon-rotate hide-actions>
                        <template v-slot="{ open }">
                          <v-row no-gutters>
                            <v-col cols="12" md="2">
                              <v-icon class="icon">{{
                                open ? "mdi-minus-box-outline" : "mdi-plus-box-outline"
                              }}</v-icon>
                            </v-col>
                            <v-col cols="12" md="10">
                              <div class="header">{{ item.country }}</div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-radio-group v-model="region" :rules="regionRules">
                          <v-radio
                            v-for="(region, index) in item.regions"
                            :key="index"
                            :label="region.name"
                            :value="item.country + '-' + region.name"
                          ></v-radio>
                        </v-radio-group>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="2" class="column-system">
              <div class="report-info system">
                <div class="title">4. System ID</div>
                <div class="system-id expansion-panels">
                  <v-expansion-panels multiple v-model="systemPanels">
                    <v-expansion-panel v-for="(item, i) in mockSystems" :key="i">
                      <v-expansion-panel-header disable-icon-rotate hide-actions>
                        <template v-slot="{ open }">
                          <v-row no-gutters>
                            <v-col cols="12" md="2">
                              <v-icon class="icon">{{
                                open ? "mdi-minus-box-outline" : "mdi-plus-box-outline"
                              }}</v-icon>
                            </v-col>
                            <v-col cols="12" md="10">
                              <div class="header">{{ item.systemId }}</div>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-checkbox
                          v-for="(device, index) in item.devices"
                          :key="index"
                          v-model="systems"
                          :label="device.name"
                          :value="item.systemId + '-' + device.name"
                          :rules="deviceRules"
                        ></v-checkbox>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <!-- <v-btn :ripple="false" @click="revert">Revert</v-btn> -->
                </div>
              </div>
              <div class="btn-wrapper">
                <v-icon v-if="createLoading">mdi-spin mdi-loading</v-icon>
                <v-btn :ripple="false" :disabled="!valid || createLoading" type="submit"
                  >Create</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <div class="table-wrapper">
          <v-data-table
            :headers="headers"
            :items="reports"
            :items-per-page="pageSize"
            :search="search"
            class="search-report-table"
            no-data-text="No Data"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="footer"
            :loading="loading"
          >
            <template v-slot:top>
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <h2>View Reports</h2>
                </v-col>
                <v-spacer />
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="search"
                    label="Search Reports"
                    class="search-report"
                    append-icon="mdi-magnify"
                    autocomplete="new-password"
                    rounded
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="action-items">
                <v-btn :ripple="false" icon class="action-download-icon">
                  <v-img
                    alt="Users"
                    contain
                    src="../../assets/tables/button_save_CSV.svg"
                    transition="scale-transition"
                    @click="downloadCSV(item)"
                  />
                </v-btn>
                <v-btn :ripple="false" icon class="action-delete-icon">
                  <v-img
                    alt="Users"
                    class="shrink mr-2"
                    contain
                    src="../../assets/tables/button_delete_doc.svg"
                    transition="scale-transition"
                    @click="openConfirmDialog(item)"
                  />
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="messageDialog"
      max-width="700"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" md="9" style="padding-top: 6px">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12" md="3" class="text-right">
              <v-btn class="form-btn" :ripple="false" @click="closeMessage">OK</v-btn>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="700px"
      content-class="report-dialog"
    >
      <v-card>
        <v-card-title>Confirm</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" md="7" style="padding-top: 6px">
              <span
                >Are you sure you want to delete report
                {{ reportInfo ? reportInfo.name : "" }}?</span
              >
            </v-col>
            <v-col cols="12" md="5" class="text-right">
              <v-btn
                class="cancel-button form-btn"
                :ripple="false"
                @click="closeConfirmDialog"
                >CANCEL</v-btn
              >
              <v-btn class="form-btn" :ripple="false" @click="deleteReport(reportInfo)"
                >YES</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" src="../../assets/css/report.scss"></style>

<script>
import { mockTemplates, mockRegions, mockSystems } from "../../mock-data";
import { reportTypes, footerProps } from "../../contants";
import { reportService } from "../../services";
import moment from "moment";
import _ from "lodash";

export default {
  data() {
    return {
      pageSize: 3,
      pageNo: 1,
      search: "",
      reportName: "",
      reportTemplateName: "",
      reportType: "",
      reportTemplate: null,
      region: null,
      systems: [],
      menu: false,
      dates: [],
      saveTemplate: false,
      total: 0,
      totalPage: 0,
      options: {},
      regionPanel: [0],
      systemPanels: [0],
      footer: null,
      headers: [
        { text: "Created Reports", value: "name" },
        { text: "Report Type", value: "type" },
        { text: "Device ID", value: "deviceId" },
        { text: "Created Date", value: "created_at" },
        { text: "File Size", value: "file_size" },
        { text: "Actions", value: "actions", sortable: false, filterable: false },
      ],
      reports: [],
      reportTypes,
      reportTemplates: [...mockTemplates],
      currentDate: moment(),
      mockRegions,
      mockSystems,
      reportNameRules: [(v) => !!v || "Report Name is required"],
      reportTypeRules: [(v) => !!v || "Report Type is required"],
      regionRules: [(v) => !!v || "Region is required"],
      reportTemplateRules: [(v) => !!v || "Report Template is required"],
      reportTemplateNameRules: [(v) => !!v || "Report Template Name is required"],
      rpTemplateNameCountValidationMsg: "",
      rpTemplateNameCount: 0,
      valid: false,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      loading: false,
      timeout: null,
      confirmDialog: false,
      reportInfo: null,
      createLoading: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  created() {
    this.footer = Object.assign({}, this.footer, footerProps);
  },
  mounted() {
    const els = document.getElementsByClassName("report-page");
    if (els && els.length) {
      els[0].addEventListener("click", () => {
        this.$refs.select.blur();
        this.menu = false;
      });
    }
  },
  methods: {
    // searchReports: _.debounce(function (val) {
    //   this.search = val;
    //   this.getDataFromApi(val);
    // }, 300),
    getDataFromApi() {
      // clearTimeout(this.timeout);
      // this.timeout = setTimeout(() => {
      const { sortBy, sortDesc, page } = this.options;
      this.pageNo = page;
      const pageNo = (page - 1) * this.pageSize;
      const param = {
        search: this.search || "",
        pageNo: pageNo,
        pageSize: this.pageSize,
      };
      if (sortBy.length === 1 && sortDesc.length === 1) {
        param.sortBy = sortBy[0];
        param.sortType = sortDesc[0] ? "DESC" : "ASC";
      }
      this.getListReports(param);
      // }, 300);
    },
    updatePagination(pagination) {
      this.pageNo = pagination.page;
      this.pageSize = pagination.itemsPerPage;
      this.$set(this.footer, "pageText", `${this.pageNo} of ${this.total}`);
    },
    getListReports(param) {
      this.loading = true;
      reportService
        .getListReports(param)
        .then((res) => {
          if (res?.data?.data?.data) {
            const data = res.data.data.data;
            data.map((val) => {
              val.created_at = moment(val.created_at).format("MMM DD YYYY | HH:mm");
              val.deviceId = "";
              if (val.system_id?.length) {
                val.system_id.map((systems, index) => {
                  if (index > 0) {
                    val.deviceId += ", ";
                  }
                  val.deviceId =
                    val.deviceId + systems[Object.keys(systems)[0]].join(", ");
                });
              }
              val.deviceId = val.deviceId.substr(0, 30) + "...";
              return val;
            });
            this.totalPage = Math.ceil((res.data.data.total || 0) / this.pageSize);
            this.total = res.data.data.total;
            this.$set(this.footer, "pageText", `${this.pageNo} of ${this.totalPage}`);
            this.reports = [...data];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      const data = this.prepareData();
      this.createLoading = true;
      reportService
        .createReport(data)
        .then(() => {
          this.getDataFromApi();
          this.apiMessage = "Report has been successfully created";
          this.apiTitle = "Success";
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "Report cannot be created";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    prepareData() {
      const data = {
        name: this.reportName || "",
        template: this.reportTemplate || "",
        template_name: this.reportTemplateName || "",
        start_date: this.dates[0],
        end_date: this.dates[1],
        save_template: this.saveTemplate,
        type: this.reportType,
      };
      const regions = this.region.split("-");
      data.country = regions[0];
      data.region = regions[1];
      const systems = [];
      this.systems.map((val) => {
        const vals = val.split("-");
        const obj = _.find(systems, (obj) => obj[vals[0]] != null);
        if (obj) {
          obj[vals[0]].push(vals[1]);
        } else {
          systems.push({ [vals[0]]: [vals[1]] });
        }
      });
      data.system_id = [...systems];
      return data;
    },
    revert() {
      this.$refs.form.reset();
    },
    downloadCSV(item) {
      if (item?.file) {
        reportService.downloadCSV(item.file).then((res) => {
          const data = res.data;
          const fileNames = item.file.split("/");
          const name = fileNames[fileNames.length - 1];
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(data, `${name}`);
            return;
          }
          const blob = new Blob([data], { type: data.type });
          const url = URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          document.body.appendChild(anchor);
          anchor.href = url;
          anchor.setAttribute("download", `${name}`);
          anchor.click();
          document.body.removeChild(anchor);
          URL.revokeObjectURL(url);
        });
      }
    },
    deleteReport(item) {
      reportService
        .deleteReport(item.id)
        .then(() => {
          this.getDataFromApi();
          this.apiMessage = "Report has been successfully deleted";
          this.apiTitle = "Success";
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "Report cannot be deleted";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        })
        .finally(() => {
          this.closeConfirmDialog();
        });
    },
    allowedDates(val) {
      return moment(val).isSameOrAfter(this.currentDate, "day");
    },
    closeMessage() {
      this.messageDialog = false;
    },
    openConfirmDialog(item) {
      this.reportInfo = Object.assign({}, this.reportInfo, item);
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.reportInfo = null;
      this.confirmDialog = false;
    },
  },
  computed: {
    rpTemplateNameValidation() {
      return [
        (v) => {
          if (this.rpTemplateNameCount == 0) {
            this.rpTemplateNameCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.rpTemplateNameCountValidationMsg = "Report Template Name is required";
            return false;
          } else {
            this.rpTemplateNameCountValidationMsg = "";
            return true;
          }
        },
      ];
    },
    dateRangeText() {
      const dates = [...this.dates];
      if (dates?.length) {
        dates.sort((a, b) => {
          return moment(a).isSameOrBefore(b, "day");
        });

        const startDate = dates[0] ? moment(dates[0]).format("MM/DD/YYYY") : "";
        const endDate = dates[1] ? moment(dates[1]).format("MM/DD/YYYY") : "";
        return startDate + " - " + endDate;
      }
      // return dates.join(" - ");

      return "";
    },
    dateRangeRules() {
      if (!this.dates?.length) {
        return ["Date Range is required"];
      }
      if (this.dates.length < 2) {
        return ["Date Range is invalid"];
      }
      return [true];
    },
    deviceRules() {
      return [this.systems.length > 0 || "At least one item must be selected"];
    },
  },
};
</script>
